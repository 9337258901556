import {
	deleteCurrentFeed,
	fetchMoreProductDataSuccess,
	fetchProductDataSuccess,
	getCurrentFeeds,
	getCurrentPage,
	getExtraProducts,
	getFeedPage,
	getFeedTextAds,
	getFeedTextAdsIndex,
	getLastPosition,
	getNoMoreProductsCounter,
	getSearchFilters,
	getVendorProps,
	getYahooProps,
	setFetchDataFail,
	setFetchDataLoading,
	setFetchMoreDataLoading,
	setIsSearchBar,
	setRequestId,
} from 'src/lib/redux/modules/search';
import { IS_CLIENT, IS_DEBUG, REQUEST_ID_PREFIX, YAHOO_VENDOR } from 'src/lib/constants/general';
import { INIT_SEARCH_TIMESTAMP, YAHOO_PRODUCT } from 'src/lib/constants/general';
import _sortBy from 'lodash/sortBy';
import { put, select, takeEvery } from 'redux-saga/effects';
import {
	getIsDiscoveryMode,
	getIsComplaintMode,
	getCurrentDevice,
	setSSRData,
	getSSRData,
	getWhoAmI,
} from 'src/lib/redux/modules/settings';
import { fetchProductData } from 'src/lib/api';
import TestsManager from 'src/lib/managers/TestsManager';
import PerformanceManager from 'src/lib/managers/PerformanceManager';
import debug from 'src/lib/logger';
import { isNewFeed } from 'src/lib/whoami';
import { getCurrentCategoryPage } from 'src/lib/redux/modules/settings';
import { uuid } from 'src/lib/utils';
import TrackingReduxManager from 'src/lib/managers/TrackingReduxManager';
import { getMiniStore, setMiniStore } from 'src/lib/managers/StorageManager';
import { getSnapshotKey } from 'src/lib/snapshots';

export const SEARCH_PRODUCTS_SAGA = 'SEARCH_PRODUCTS_SAGA';
export const SEARCH_MORE_PRODUCTS_SAGA = 'SEARCH_MORE_PRODUCTS_SAGA';

export function searchProductsSaga(queryParams = {}, ssrData = {}, requestId = '') {
	return {
		type: SEARCH_PRODUCTS_SAGA,
		payload: { queryParams, ssrData, requestId },
	};
}

export function searchMoreProductsSaga(queryParams = {}) {
	return {
		type: SEARCH_MORE_PRODUCTS_SAGA,
		payload: { queryParams },
	};
}

/////////////// ON SEARCH /////////////////////

function* getMaxYResultsParam(queryParams = {}) {
	if (queryParams.zby === '1') {
		return 0;
	}

	const isDiscoveryMode = yield select(state => getIsDiscoveryMode(state.settings));

	if (isDiscoveryMode) {
		return 16;
	}

	const isInMobile = yield isMobile();
	if (isInMobile) {
		return 2;
	}
	return 4;
}

// Yahoo props must always be the last vendor props sent from the server
// In case of revisiting url Non Yahoo props should be the last vendor props sent
function* buildVendorProps(queryParams) {
	let vendorProps = {};
	const ssrData = yield select(state => getSSRData(state.settings)) || {};
	const _vendorProps = yield select(state => getVendorProps(state.search) || {});
	const { vendorPropsByUrl = {} } = getMiniStore();

	if (vendorPropsByUrl[getSnapshotKey(queryParams, ssrData)]) {
		const yahooProps = yield select(state => getYahooProps(state.search));
		// in case we were in this page before, send non yahoo vendor props from the old vendor props, for getting the same products, but yahoo props will be from the current vendor props
		// eslint-disable-next-line
		const { y, ...nonYProps } = vendorPropsByUrl[getSnapshotKey(queryParams, ssrData)];
		vendorProps = {
			y: yahooProps,
			...nonYProps,
		};

		return JSON.stringify(vendorProps);
	}
	return JSON.stringify(_vendorProps);
}

export function* setMiniStoreWithCookie(value) {
	const whoami = yield select(state => getWhoAmI(state.settings));
	setMiniStore(value, whoami);
}

export function* onSearchProducts(action) {
	try {
		const currentCategory = yield select(state => getCurrentCategoryPage(state.settings));
		const { lastUserAction, vendorPropsByUrl = {} } = getMiniStore();
		const requestId = TrackingReduxManager.getEntry('requestId') || uuid(REQUEST_ID_PREFIX);
		yield put(setRequestId({ requestId }));

		if (action.payload && action.payload.queryParams && !action.payload.queryParams.q && !currentCategory.categoryId) {
			yield put(setIsSearchBar());
			return;
		}
		yield put(setFetchDataLoading(true));
		yield put(deleteCurrentFeed());
		const queryParamsWithTest = checkIsAlreadyInTest(action.payload);
		const { test, queryParams } = queryParamsWithTest;
		const maxYResults = yield getMaxYResultsParam(queryParams);

		const fetchProps = {
			page: 1,
			...queryParams,
			vendorProps: yield buildVendorProps(queryParams),
			categoryId: currentCategory.categoryId,
			...test,
			maxYResults,
			requestId,
			prevPage: lastUserAction === 'prev',
		};

		setMiniStore({
			vendorPropsByUrl: {
				...vendorPropsByUrl,
				[getSnapshotKey(queryParams, yield select(state => getSSRData(state.settings)))]: JSON.parse(fetchProps.vendorProps),
			},
		});

		const fetchOptions = {};

		if (action.payload.ssrData && Object.keys(action.payload.ssrData).length > 0) {
			fetchOptions.hostname = action.payload.ssrData.host;
			fetchOptions.userAgent = action.payload.ssrData.userAgent;
			fetchOptions.referrer = action.payload.ssrData.referrer;
			fetchOptions.clientIp = action.payload.ssrData.clientIp;
			debug(
				`DSA Fetch From Feed`,
				JSON.stringify(action.payload.ssrData.resolvedUrl),
				JSON.stringify(fetchProps),
				JSON.stringify(fetchOptions)
			);
		}

		if (isNewFeed()) {
			fetchProps.isTracking = fetchProps.trackTest;
			delete fetchProps.trackTest;
		}

		const data = yield fetchProductData(fetchProps, fetchOptions);
		if (!IS_CLIENT) {
			const ssrData = yield select(state => getSSRData(state.settings));
			const { testData } = data;
			yield put(setSSRData({ ...ssrData, testData }));
		}

		nextPosition(true);
		afterFetchData({ ...queryParamsWithTest, data });

		// For ssr
		setMiniStore({
			vendorProps: fetchProps.vendorProps,
		});

		yield updateFeedResults(data);
	} catch (err) {
		console.error('Error fetch data from api', err);
		yield put(setFetchDataFail());
	}
}

function* updateFeedResults(payload) {
	let { page, textAds, productAds, vendorProps = {}, relatedSearches, filters, qa = {} } = payload;
	const currentPage = yield select(state => getCurrentPage(state.search));
	const extraProducts = yield select(state => getExtraProducts(state.search));
	const feedTextAdsIndex = yield select(state => getFeedTextAdsIndex(state.search));
	let feedPage = yield select(state => getFeedPage(state.search));

	let feeds = {
		currentFeeds: [],
		extraProducts: [],
	};

	feedPage = parseInt(page);
	const buildFeedProps = {
		feedTextAdsIndex,
		prevFeeds: [],
		newAds: { productAds: [...extraProducts, ...productAds], textAds },
		currentPage,
	};
	feeds = yield buildFeeds(buildFeedProps);

	const isEmptyProducts = productAds.length === 0;
	const isEmptyTextAds = textAds.length === 0;
	debug('textads', textAds);

	const feedResults = {
		isLoadingProducts: false,
		isLoadedProducts: true,
		extraProducts: feeds.extraProducts,
		currentFeeds: feeds.currentFeeds,
		contents: [],
		feedPage,
		currentPage: parseInt(page),
		feedTextAds: textAds,
		feedTextAdsIndex: feeds.feedTextAdsLastIndex,
		vendorProps,
		noMoreProducts: isEmptyProducts,
		noMoreProductsCounter: 0,
		relatedSearches,
		filters,
		isLoadedProductsFail: false,
		isNoResults: isEmptyProducts,
		isNoResultsAtAll: isEmptyProducts && isEmptyTextAds,
		isLoadedMoreProducts: false,
		lastPosition: feeds.lastPosition,
		tomer: qa,
		// These two props are for yahoo beacon, do not delete these
		renderedYahooAds: [],
		yahooProducts: productAds.filter(p => p.vendorId === YAHOO_PRODUCT),
		currentTextAds: textAds,
		//
	};

	yield put(fetchProductDataSuccess(feedResults));
}

/////////////// END ON SEARCH /////////////////////

/////////////// ON SEARCH MORE /////////////////////

export function* onSearchMoreProducts(action) {
	try {
		const requestId = uuid(REQUEST_ID_PREFIX);
		yield put(setFetchMoreDataLoading(true));
		yield put(setRequestId({ requestId }));

		const queryParams = action.payload.queryParams;
		const { categoryId } = yield select(state => getCurrentCategoryPage(state.settings));
		const feedPage = yield select(state => getFeedPage(state.search));
		const queryParamsWithTest = checkIsAlreadyInTest({ ...action.payload, queryParams }, feedPage);
		const isExtraProducts = yield hasExtraProducts();
		const isComplaint = yield select(state => getIsComplaintMode(state.settings));
		const filters = yield select(state => getSearchFilters(state.search));
		const currentPage = yield select(state => getCurrentPage(state.search));
		const nextPage = isComplaint && queryParams.page ? queryParams.page : currentPage + 1;
		const maxYResults = yield getMaxYResultsParam(queryParams);
		const { lastUserAction, vendorPropsByUrl = {} } = getMiniStore();

		const fetchParams = {
			...queryParamsWithTest.queryParams,
			vendorProps: yield buildVendorProps(queryParams),
			...queryParamsWithTest.test,
			categoryId,
			maxYResults,
			page: isComplaint && queryParams.page ? queryParams.page : feedPage + 1,
			requestId,
			prevPage: lastUserAction === 'prev',
		};

		setMiniStore({
			vendorPropsByUrl: {
				...vendorPropsByUrl,
				[getSnapshotKey(queryParams)]: JSON.parse(fetchParams.vendorProps),
			},
		});

		let data = { page: nextPage, textAds: [], productAds: [], filters };
		let feedResults;

		let isFetched = false;

		fetchParams.enableYProducts = false;

		if (isNewFeed()) {
			fetchParams.isTracking = fetchParams.trackTest;
			delete fetchParams.trackTest;
		}

		if (!isExtraProducts) {
			// Go fetch from all vendors
			isFetched = true;
			fetchParams.page = isComplaint && queryParams.page ? queryParams.page : feedPage + 1;
			debug('Fetch All Vendors', fetchParams);

			debug(
				`Go to feed, 
					page: ${fetchParams.page} |
					vendors: 'All' |
					maxYResults: ${fetchParams.maxYResults} | 
					enableYProducts: ${fetchParams.enableYProducts === false ? false : true} |
					isExtraProducts: ${isExtraProducts}
				`
			);
			data = yield fetchProductData(fetchParams);
		} else {
			// Go fetch only yahoo textads
			debug('Fetch Only Yahoo', fetchParams);
			debug(
				`Go to feed, 
					page: ${fetchParams.page} |
					vendors: 'y' |
					maxYResults: ${fetchParams.maxYResults} | 
					enableYProducts: ${fetchParams.enableYProducts === false ? false : true} |
					isExtraProducts: ${isExtraProducts}
				`
			);

			data = yield fetchProductData({ ...fetchParams, vendors: YAHOO_VENDOR });
		}

		if (isComplaint && isFetched) {
			nextPosition(true);
		}

		feedResults = yield aggregateMoreFeedResults(data, isFetched);

		afterFetchData({ ...queryParamsWithTest, data, nextPage });
		yield put(fetchMoreProductDataSuccess(feedResults));
	} catch (err) {
		console.error('TCL: Error fetch data from api', err);
	}
}

function* aggregateMoreFeedResults(payload, isFetched) {
	let { page, textAds = [], productAds, filters, vendorProps = null } = payload;

	const currentFeeds = yield select(state => getCurrentFeeds(state.search));
	let feedTextAds = yield select(state => getFeedTextAds(state.search));
	const noMoreProductsCounter = yield select(state => getNoMoreProductsCounter(state.search));
	const currentPage = yield select(state => getCurrentPage(state.search));
	const extraProducts = yield select(state => getExtraProducts(state.search));
	const feedTextAdsIndex = yield select(state => getFeedTextAdsIndex(state.search));
	const isExtraProducts = yield hasExtraProducts();
	const stateVendorProps = yield select(state => getVendorProps(state.search));
	const lastPosition = yield select(state => getLastPosition(state.search));
	let feedPage = yield select(state => getFeedPage(state.search));

	let feeds = {
		currentFeeds: [],
		extraProducts: [],
		feedTextAdsIndex,
	};

	// feedTextAds = feedTextAds.filter(ad => !ad.dummy); // remove last page dummy textads
	feeds = yield buildFeeds({
		prevFeeds: currentFeeds,
		newAds: { productAds: [...extraProducts, ...productAds], textAds: [...feedTextAds, ...textAds] },
		currentPage,
		searchMore: true,
		feedTextAdsIndex,
	});

	debug('textads more', [...feedTextAds, ...textAds]);
	debug('productsads more', [...extraProducts, ...productAds]);
	const _noMoreProductsCounter = productAds.length === 0 && !isExtraProducts ? noMoreProductsCounter + 1 : noMoreProductsCounter;
	return {
		isLoadingMoreProducts: false,
		isLoadedMoreProducts: true,
		extraProducts: feeds.extraProducts,
		currentFeeds: feeds.currentFeeds,
		feedTextAdsIndex: feeds.feedTextAdsLastIndex,
		feedPage: isFetched ? parseInt(page) : feedPage,
		currentPage: currentPage + 1,
		feedTextAds: [...feedTextAds, ...textAds],
		noMoreProducts: productAds.length === 0 && !isExtraProducts,
		noMoreProductsCounter: _noMoreProductsCounter,
		filters,
		isLoadedProductsFail: false,
		vendorProps: vendorProps || stateVendorProps,
		lastPosition: !feeds.lastPosition ? lastPosition : feeds.lastPosition,
		// These three props are for yahoo beacon, do not delete these
		renderedYahooAds: [],
		yahooProducts: productAds.filter(p => p.vendorId === YAHOO_PRODUCT),
		currentTextAds: textAds,
		//
	};
}

function* hasExtraProducts() {
	const extraProducts = yield select(state => getExtraProducts(state.search));
	const feedSize = yield calcFeedSize();
	return extraProducts.length >= feedSize;
}

/////////////// END ON SEARCH MORE /////////////////////

/////////////// SHARED SEARCH FUNCTIONS /////////////////////

export function afterFetchData(payload) {
	const { data = {}, isForcedTest, nextPage, queryParams } = payload;

	queryParams.page = nextPage;
	checkIsEnterToTest({ data, isForcedTest });

	delete queryParams['sessionStart'];
	PerformanceManager.set(INIT_SEARCH_TIMESTAMP, null);
}

function checkIsEnterToTest(props) {
	const { data = {}, isForcedTest } = props;
	const { testData = {} } = data;
	const currentTest = TestsManager.getCurrentTest();
	const isInTest = currentTest.testId;
	if (
		(isInTest && +testData.testId === +currentTest.testId && testData.testGroup === currentTest.testGroup) || // just for update trackTest
		(!isInTest && testData.testId) ||
		(isForcedTest && testData.testId)
	) {
		TestsManager.setCurrentTest(testData);
	}
}

function checkIsAlreadyInTest(payload, feedPage) {
	let { queryParams, ssrData } = payload;

	const currentTest = TestsManager.getCurrentTest();
	const forcedTest = TestsManager.getForcedTest(ssrData);
	const isInTest = currentTest.testId;

	const isForcedTest = Boolean(forcedTest && forcedTest.ftid);
	if (!isInTest) {
		queryParams = {
			...queryParams,
			...forcedTest,
		};
	}
	return {
		test: (() => {
			if (feedPage > 0) {
				return currentTest;
			}
			return isForcedTest ? forcedTest : currentTest;
		})(),
		queryParams,
		isForcedTest,
		...payload,
	};
}

export function calcFeedSize() { //add function* if recomment yield function
	// const isInMobile = yield isMobile();
	return 150;

	// let feedSize = 25;
	//
	// if (isInMobile) {
	// 	feedSize = 24;
	// } else {
	// 	feedSize = 25;
	// }
	// return feedSize;
}

function* isMobile() {
	const currentDevice = yield select(state => getCurrentDevice(state.settings));
	return currentDevice.mobile;
}
/////////////// END SHARED SEARCH FUNCTIONS /////////////////////

///////////////////////////// BUILD FEED FUNCTIONS /////////////////////////////

function* buildFeeds(props) {
	let { prevFeeds = [], newAds = {}, currentPage, searchMore, feedTextAdsIndex } = props;
	const { textAds } = newAds;

	let feedSize = yield calcFeedSize();

	const yahooFirst = _sortBy(newAds.productAds, product => product.vendorId !== YAHOO_PRODUCT);
	newAds = {
		...newAds,
		productAds: yahooFirst,
	};

	const { productsForFeed, extraProducts } = sliceProducts({ newAds, feedSize });
	const { feed, feedTextAdsLastIndex, lastPosition } = yield buildCurrentFeed({
		feedTextAdsIndex,
		productAds: productsForFeed,
		textAds,
		currentPage,
		searchMore,
		feedSize,
	});
	let currentFeeds = [...prevFeeds, feed];

	if (currentFeeds.length === 1 && currentFeeds[0].length === 0) {
		currentFeeds = [];
	}

	return {
		currentFeeds,
		extraProducts,
		feedTextAdsLastIndex,
		lastPosition,
	};
}

const sliceProducts = props => {
	let { newAds, feedSize = 150 } = props;
	let { productAds } = newAds;

	const productsForFeed = productAds.slice(0, feedSize);
	const extraProducts = productAds.slice(feedSize, productAds.length);
	return {
		productsForFeed,
		extraProducts,
	};
};

// TODO - move all this settings to constants
function* buildCurrentFeed(props) {
	let { productAds = [], textAds = [], searchMore = false, feedTextAdsIndex, feedSize } = props;

	// default scramble props
	let scrambleProps = {
		textAdsToScramble: 4,
		scrambleAfter: 0,
		textAdsIndexToBegin: feedTextAdsIndex,
		amountOfScrambles: 1000,
	};

	scrambleProps = yield getFeedScrambleProps({ scrambleProps, textAds, productAds });

	let { feed, feedTextAdsLastIndex, lastPosition } = scrambleFeed({
		productAds,
		textAds,
		feedSize,
		searchMore,
		...scrambleProps,
	});
	if (IS_DEBUG) {
		debug(`textAds`, textAds);
		debug(`feed`, feed);
	}

	return { feed, feedTextAdsLastIndex, lastPosition };
}

function* getFeedScrambleProps({ scrambleProps, textAds, productAds }) {
	const isDiscoveryMode = yield select(state => getIsDiscoveryMode(state.settings));

	if (yield isMobile()) {
		if (isDiscoveryMode) {
			scrambleProps.amountOfScrambles = 1;
			scrambleProps.scrambleAfter = 1000;
			scrambleProps.textAdsToScramble = 1000;
			scrambleProps.startWithTextAds = false;
			scrambleProps.externalContinueCondition = (textAdsCounter, productAdsCounter) => {
				return textAdsCounter !== (textAds || []).length || productAdsCounter !== (productAds || []).length;
			};
		} else {
			scrambleProps.amountOfScrambles = 1;
			scrambleProps.scrambleAfter = 4;
			scrambleProps.textAdsToScramble = 2;
			scrambleProps.startWithTextAds = false;
		}
	} else {
		scrambleProps.textAdsToScramble = 4;
		scrambleProps.scrambleAfter = 0;
		scrambleProps.amountOfScrambles = 1;
		scrambleProps.startWithTextAds = true;
	}

	return scrambleProps;
}

/**
 * Scramble textads and products ads for feed display
 * @param {*} props
* 	customAd = {
		add: productIndex => productIndex === 10,
		adType: 'Reignn',
	};
 */
const scrambleFeed = props => {
	let {
		productAds,
		textAds,
		// new feed array after this amount of products
		scrambleAfter = 8,
		// amount of text ads after a bulk of products
		textAdsToScramble = 3,
		// maximum amount of scrambles
		amountOfScrambles = 1000,
		// textad index to begin the scramble
		textAdsIndexToBegin = 0,
		// is next iteration of scramble will be textads or product ads
		startWithTextAds = true,
		// object with adType and add function to decide is add this component ad to feed or not
		customAd = null,
		externalContinueCondition = null,
	} = props;

	const feed = [];
	let productAdsCounter = 0;
	let textAdsCounter = textAdsIndexToBegin;
	let feedIndex = 0;
	let scramblesCounter = 0;
	let productsToSlice = scrambleAfter;
	let position = null;
	if (scrambleAfter === 0) {
		startWithTextAds = true;
		productsToSlice = productAds.length;
	}
	if (productAds.length === 0 && textAds.length > 0) {
		// add all items from arr2 to finalArr
		feed.push(...[{ type: 'text', ads: textAds.slice(textAdsCounter) }]);
		textAdsCounter = textAds.length;
	}

	let productsCounterFromLastTimeScramble = 0;

	const continueCondition = externalContinueCondition || (() => productAdsCounter < productAds.length);

	while (continueCondition(textAdsCounter, productAdsCounter)) {
		if (startWithTextAds && scramblesCounter < amountOfScrambles) {
			// if (textAdsCounter === textAds.length) {
			// 	textAdsCounter = 0;
			// }
			const textAdsToFeed = textAds.slice(textAdsCounter, textAdsCounter + textAdsToScramble).map(textad => {
				position = nextPosition();
				return {
					...textad,
					position,
				};
			});
			feed[feedIndex] = { ads: textAdsToFeed, type: 'text' };
			textAdsCounter = textAdsCounter + textAdsToFeed.length;
			startWithTextAds = false;
			scramblesCounter += 1;
			productsCounterFromLastTimeScramble = 0;
		} else {
			const productAdsToFeed = productAds.slice(productAdsCounter, productAdsCounter + productsToSlice).map(productAd => {
				position = nextPosition();
				return {
					...productAd,
					position,
				};
			});
			feed[feedIndex] = { ads: productAdsToFeed, type: 'product' };
			startWithTextAds = true;
			productAdsCounter = productAdsCounter + productAdsToFeed.length;
			productsCounterFromLastTimeScramble += productAdsToFeed.length;
		}
		if (customAd && customAd.add(productAdsCounter, productsCounterFromLastTimeScramble)) {
			feedIndex += 1;
			feed[feedIndex] = { ads: [{ adType: customAd.adType, tag: customAd.getTag(productAdsCounter) }], type: 'reignn' };
		}
		feedIndex += 1;
	}
	return {
		feed,
		feedTextAdsLastIndex: textAdsCounter,
		lastPosition: position,
	};
};

const nextPosition = (
	p =>
	(init = false) => {
		if (init) p = -1;
		else p++;
		return p;
	}
)(-1);

///////////////////////////// END BUILD FEED FUNCTIONS /////////////////////////////?

export default function* root() {
	yield takeEvery(SEARCH_PRODUCTS_SAGA, onSearchProducts);
	yield takeEvery(SEARCH_MORE_PRODUCTS_SAGA, onSearchMoreProducts);
}
