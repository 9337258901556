import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

export const Image = props => {
	// beaconProps - DEPRECATED
	const { className, url, width, height, alt, customStyles, onClick, lazyLoad, onLoad, loader } = props;

	const style = {
		width,
		height,
	};

	if (loader) {
		return <img src="/fresh/images/loading-spinner.svg" />;
	}

	if (lazyLoad) {
		return (
			<LazyLoad once={true} offset={100}>
				<img className={className} style={{ ...style, ...customStyles }} src={url} alt={alt} onClick={onClick} onLoad={onLoad} />
			</LazyLoad>
		);
	}

	return <img className={className} style={{ ...style, ...customStyles }} src={url} alt={alt} onClick={onClick} onLoad={onLoad} />;
};

Image.propTypes = {
	url: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	alt: PropTypes.string,
	className: PropTypes.string,
	customStyles: PropTypes.object,
	onClick: PropTypes.func,
	effect: PropTypes.string,
	lazyLoad: PropTypes.bool,
	loader: PropTypes.bool,
};

Image.defaultProps = {
	width: null,
	height: null,
	alt: '',
	customStyles: {},
	className: '',
	onClick: null,
	effect: 'opacity',
	lazyLoad: true,
	loader: false,
};
