import { buildUrl } from "./build-url";
import { IS_CLIENT } from "./constants/general";
import parseUrl from "./prescripts/utils/parseUrl";

export const getSnapshotKey = (queryParams, ssrData = {}, pToSearchPage = false) => {
	let snapshotKey = '';
	if (IS_CLIENT) {
		snapshotKey = location.href;
		if (pToSearchPage) {
			snapshotKey = snapshotKey.replace(/(\/p\/.*?)(?=\?|$)/g, "/search");
		}
		const urlObj = parseUrl(snapshotKey);
		if (!queryParams.page) {
			if (pToSearchPage) {
				urlObj.query.q = ssrData.urlParams.q;
			}
			snapshotKey = buildUrl(urlObj.url, { ...urlObj.query, page: 1 });
			snapshotKey += '&vprops=1';
		}
	} else {
		const { resolvedUrl, host, urlParams } = ssrData;
		snapshotKey = host + resolvedUrl;
		if (!urlParams.page) {
			snapshotKey = buildUrl(snapshotKey, { ...urlParams, page: 1 });
			snapshotKey += '&vprops=1';
		}
	}

	return snapshotKey;
};
