import { setAction } from 'src/lib/redux/helpers';
import { searchMoreProductsSaga, searchProductsSaga } from 'src/lib/redux/sagas/feedSaga';

// labels
export const SET_SEARCH_BAR = 'SET_SEARCH_BAR';
export const SET_RESET_OPEN_SEARCH_BAR = 'SET_RESET_OPEN_SEARCH_BAR';
export const SET_FILTER = 'SET_FILTER';
export const SET_CURRENT_QUERY = 'SET_CURRENT_QUERY';
export const SET_IS_LOADING_PRODUCTS = 'SET_IS_LOADING_PRODUCTS';
export const SET_IS_LOADED_PRODUCTS = 'SET_IS_LOADED_PRODUCTS';
export const SET_IS_LOADED_MORE_PRODUCTS = 'SET_IS_LOADED_MORE_PRODUCTS';
export const SET_IS_DOM_READY = 'SET_IS_DOM_READY';
export const SET_REQUEST_ID = 'SET_REQUEST_ID';

export const FETCH_PRODUCTS_DATA = 'FETCH_PRODUCTS_DATA';
export const FETCH_PRODUCTS_DATA_SUCCESS = 'FETCH_PRODUCTS_DATA_SUCCESS';
export const FETCH_PRODUCTS_DATA_FAIL = 'FETCH_PRODUCTS_DATA_FAIL';

export const FETCH_MORE_PRODUCTS_DATA = 'FETCH_MORE_PRODUCTS_DATA';
export const FETCH_MORE_PRODUCTS_DATA_SUCCESS = 'FETCH_MORE_PRODUCTS_DATA_SUCCESS';
export const FETCH_MORE_PRODUCTS_DATA_FAIL = 'FETCH_MORE_PRODUCTS_DATA_FAIL';
export const SET_FAKE_LOADING = 'SET_FAKE_LOADING';
export const PUSH_TO_EXIT = 'PUSH_TO_EXIT';
export const DELETE_CURRENT_FEED = 'DELETE_CURRENT_FEED';
export const SET_CURRENT_FEEDS = 'SET_CURRENT_FEEDS';
export const SET_FEED_TEXT_ADS_INDEX = 'SET_FEED_TEXT_ADS_INDEX';
export const SET_IS_NO_RESULTS = 'SET_IS_NO_RESULTS';
export const SET_VENDOR_PROPS = 'SET_VENDOR_PROPS';
export const REPORT_AD_RENDERED = 'REPORT_AD_RENDERED';

// initial state
const initialState = {
	isFilter: false,
	isFixedSearchBar: false,
	isLoadedProducts: false,
	openSearchBar: false,
	isSearchInputFocused: true,
	currentQuery: '',
	isLoadingProducts: true,
	isLoadedProductsFail: false,
	isDomReady: false,
	currentFeeds: [],
	extraProducts: [],
	relatedSearches: [],
	currentPage: 0,
	feedPage: 1,
	noMoreProducts: false,
	feedTextAds: [],
	feedTextAdsIndex: 0,
	contents: [],
	noMoreProductsCounter: 0,
	isNoResults: false,
	isNoResultsAtAll: false,
	tomer: {},
	renderedYahooAds: [],
	requestId: '',
};

// reducer
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case SET_SEARCH_BAR:
			return {
				...state,
				isFixedSearchBar: action.payload !== null ? action.payload : !state.isFixedSearchBar,
				openSearchBar: !state.openSearchBar,
			};

		case SET_RESET_OPEN_SEARCH_BAR:
			return {
				...state,
				openSearchBar: false,
			};

		case SET_FILTER:
			return {
				...state,
				isFilter: action.payload !== null ? action.payload : !state.isFilter,
			};

		case SET_CURRENT_QUERY:
			return {
				...state,
				currentQuery: action.payload,
			};

		case FETCH_PRODUCTS_DATA:
			return {
				...state,
				isLoadingProducts: true,
				isLoadedProducts: false,
			};

		case FETCH_PRODUCTS_DATA_SUCCESS: {
			return {
				...state,
				...action.payload,
			};
		}

		case FETCH_PRODUCTS_DATA_FAIL:
			return {
				...state,
				isLoadedProducts: false,
				isLoadingProducts: false,
				isLoadedProductsFail: true,
				noMoreProductsCounter: 0,
			};

		case SET_IS_LOADING_PRODUCTS:
			return {
				...state,
				isLoadingProducts: action.payload !== null ? action.payload : !state.isSearchInputFocused,
			};

		case SET_IS_LOADED_MORE_PRODUCTS:
			return {
				...state,
				isLoadedMoreProducts: action.payload !== null ? action.payload : !state.isLoadedMoreProducts,
			};

		case SET_REQUEST_ID:
			return {
				...state,
				requestId: action.payload,
			};

		case FETCH_MORE_PRODUCTS_DATA:
			return {
				...state,
				isLoadingMoreProducts: true,
			};

		case FETCH_MORE_PRODUCTS_DATA_SUCCESS: {
			return {
				...state,
				...action.payload,
			};
		}

		case SET_CURRENT_FEEDS: {
			const { currentFeeds, feedIndex } = action.payload;
			const currentContents = state.contents;
			currentContents[feedIndex] = currentFeeds;
			return {
				...state,
				currentContents,
			};
		}

		case DELETE_CURRENT_FEED: {
			return {
				...state,
				extraProducts: [],
				currentFeeds: [],
				pageFeed: 1,
				currentPage: 0,
				feedTextAds: [],
				feedTextAdsIndex: 0,
			};
		}

		case SET_FEED_TEXT_ADS_INDEX: {
			return {
				...state,
				feedTextAdsIndex: action.payload,
			};
		}

		case SET_IS_NO_RESULTS: {
			return {
				...state,
				isNoResults: true,
			};
		}

		case SET_VENDOR_PROPS: {
			return {
				...state,
				vendorProps: action.payload,
			};
		}

		case REPORT_AD_RENDERED: {
			return {
				...state,
				renderedYahooAds: [...state.renderedYahooAds, action.payload],
			};
		}

		case SET_IS_DOM_READY: {
			return {
				...state,
				isDomReady: true,
			};
		}

		default:
			return state;
	}
}

// GETTERS
export const getIsFilter = state => state.isFilter;
export const getIsFixedSearchBar = state => state.isFixedSearchBar;
export const getOpenSearchBar = state => state.openSearchBar;
export const getCurrentQuery = state => state.currentQuery;
export const getRequestId = state => state.requestId;
export const getIsLoadingProducts = state => state.isLoadingProducts;
export const getIsLoadingMoreProducts = state => state.isLoadingMoreProducts;
export const getIsLoadedMoreProducts = state => state.isLoadedMoreProducts;
export const getRelatedSearches = state => state.relatedSearches;
export const getSearchFilters = state => state.filters;
export const getIsLoadedProductsFail = state => state.isLoadedProductsFail;
export const getIsLoadedProducts = state => state.isLoadedProducts;
export const getIsNoMoreProducts = state => state.noMoreProducts;
export const getIsNoMoreProductsTwice = state => state.noMoreProductsCounter === 2;
export const getCurrentFeeds = state => state.currentFeeds;
export const getFeedPage = state => state.feedPage;
export const getCurrentPage = state => state.currentPage;
export const getFeedTextAdsIndex = state => state.feedTextAdsIndex;
export const getFeedTextAds = state => state.feedTextAds;
export const getExtraProducts = state => state.extraProducts;
export const getYahooProps = state => (state.vendorProps ? state.vendorProps.yahooProps || state.vendorProps['y'] : {});
export const getVendorProps = state => state.vendorProps;
export const getNoMoreProductsCounter = state => state.noMoreProductsCounter;
export const getIsNoResults = state => state.isNoResults; // No productads
export const getIsNoResultsAtAll = state => state.isNoResultsAtAll; // No productads and no textads
export const getLastPosition = state => state.lastPosition;
export const getTomerProps = state => state.tomer;
export const getYahooProducts = state => state.yahooProducts;
export const getCurrentTextAds = state => state.currentTextAds;
export const getRenderedYahooAds = state => state.renderedYahooAds;
export const getIsDomReady = state => state.isDomReady;

//ACTIONS
export const setIsSearchBar = (is = null) => setAction(SET_SEARCH_BAR, is);
export const resetOpenSearchBar = () => setAction(SET_RESET_OPEN_SEARCH_BAR);
export const setIsFilter = (is = null) => setAction(SET_FILTER, is);
export const setCurrentQuery = query => setAction(SET_CURRENT_QUERY, query);
export const setRequestId = requestId => setAction(SET_REQUEST_ID, requestId);
export const setIsLoadingProducts = (is = null) => setAction(SET_IS_LOADING_PRODUCTS, is);
export const deleteCurrentFeed = () => setAction(DELETE_CURRENT_FEED);
export const setFetchDataLoading = () => setAction(FETCH_PRODUCTS_DATA);
export const fetchProductDataSuccess = payload => setAction(FETCH_PRODUCTS_DATA_SUCCESS, payload);
export const setFetchDataFail = () => setAction(FETCH_PRODUCTS_DATA_FAIL);
export const setFetchMoreDataLoading = () => setAction(FETCH_MORE_PRODUCTS_DATA);
export const fetchMoreProductDataSuccess = payload => setAction(FETCH_MORE_PRODUCTS_DATA_SUCCESS, payload);
export const setFeedTextAdsIndex = feedTextAdsIndex => setAction(SET_FEED_TEXT_ADS_INDEX, feedTextAdsIndex);
export const setIsNoResults = () => setAction(SET_IS_NO_RESULTS);
export const setVendorProps = payload => setAction(SET_VENDOR_PROPS, payload);
export const setIsDomReady = () => setAction(SET_IS_DOM_READY);
export const reportAdRendered = payload => setAction(REPORT_AD_RENDERED, payload);

// ACTIONS WITH PROMISES
export const searchProducts = (queryParams, ssrData, requestId) => ({
	saga: () => searchProductsSaga(queryParams, ssrData, requestId),
});

export const searchMoreProducts = queryParams => ({
	saga: () => searchMoreProductsSaga(queryParams),
});
